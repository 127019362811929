import { Typography } from 'antd';
import React from 'react';
import tailwindConfig from '../../tailwind.config';
import CartList from '../components/cart/CartList';
import MobileMenu from '../components/MobileMenu';
import CartIcon from '../icons/cart';

const { Text } = Typography;

const resolveConfig = require('tailwindcss/resolveConfig');
const config = resolveConfig(tailwindConfig);

const lightTitleColor = config.theme.colors.light.title;

const Cart = () => {
  return (
    <div className="flex flex-col h-[calc(var(--vh,1vh)*100)]">
      <div className="flex p-6 border-b border-light-divider shadow-small">
        <CartIcon color={lightTitleColor} />
        <Text className="!text-base ml-1 !text-light-title !font-semibold">
          ตะกร้าสินค้า
        </Text>
      </div>
      <CartList />
      <MobileMenu />
    </div>
  );
};

export default Cart;
